import { FormItemProps, IForm, IFormItem } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const FormApi = {
	async getAll() {
		const {
			data: { forms, error },
		} = await axiosClient.get<{ forms: IForm[]; error: boolean; message: string }>('/api/form')
		return error ? [] : forms
	},
	async getOne(id: number | string) {
		const {
			data: { form, error },
		} = await axiosClient.get<{ form: IForm; error: boolean; message: string }>(`/api/form/${id}`)
		return error ? undefined : form
	},
	async create({
		name,
		webId,
		endDate,
		startDate,
		items,
		limitAnswer = 0,
		useNotice,
		allowJs = true,
		errorContent,
		isMailSend,
		isMailWithContent,
		successContent,
	}: {
		name: string
		webId: number
		startDate?: Date
		endDate?: Date
		items?: IFormItem[]
		limitAnswer?: number
		useNotice?: string[]
		allowJs?: boolean
		isMailSend?: boolean
		isMailWithContent?: boolean
		successContent?: string
		errorContent?: string
	}) {
		const { data } = await axiosClient.post<{ id: number; error: boolean; message: string }>('/api/form/', {
			name,
			webId,
			endDate,
			startDate,
			items,
			limitAnswer,
			useNotice,
			allowJs,
			isMailSend,
			isMailWithContent,
			errorContent,
			successContent,
		})
		return data
	},
	async delete(id: number) {
		const {
			data: { error, message },
		} = await axiosClient.delete<{ error: boolean; message: string }>(`/api/form/${id}`)
		return { error, message }
	},
	async update({
		id,
		name,
		status,
		endDate,
		startDate,
		items,
		limitAnswer,
		useNotice,
		errorContent,
		successContent,
	}: {
		id: number | string
		name?: string
		status?: string
		startDate?: Date
		endDate?: Date
		items?: IFormItem[] | FormItemProps[]
		limitAnswer?: number
		useNotice?: string[]
		successContent?: string
		errorContent?: string
	}) {
		const {
			data: { error, message },
		} = await axiosClient.put<{ error: boolean; message: string }>('/api/form/' + id, {
			name,
			status,
			endDate,
			startDate,
			items,
			limitAnswer,
			useNotice,
			errorContent,
			successContent,
		})
		return { error, message }
	},
	async addOwner({ id, subOwner }: { id: number | string; subOwner: number[] }) {
		const {
			data: { error, message },
		} = await axiosClient.patch<{ error: boolean; message: string }>('/api/form/' + id + '/add-sub-owner', {
			subOwner,
		})
		return { error, message }
	},
}
